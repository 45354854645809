<template>

    <nav class="uk-navbar-container" uk-navbar>
        <div class="uk-navbar-center">

            <div class="uk-navbar-center-left">
                <ul class="uk-navbar-nav">
                    <li class="uk-active"><a href="#">Active</a></li>
                    <li>
                        <a href="#">Parent</a>
                        <div class="uk-navbar-dropdown">
                            <ul class="uk-nav uk-navbar-dropdown-nav">
                                <li class="uk-active"><a href="#">Active</a></li>
                                <li><a href="#">Item</a></li>
                                <li><a href="#">Item</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <a class="uk-navbar-item uk-logo" href="#">
                <span class="uk-margin-small-right" uk-icon="heart"></span>
                Logo
            </a>
            <div class="uk-navbar-center-right">
                <ul class="uk-navbar-nav">
                    <li><a href="#">Item</a></li>
                </ul>
            </div>

        </div>
    </nav>

</template>

<script>
export default {
    name: 'TopMenu'
};
</script>
