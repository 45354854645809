<template>
    <div>
        <TopMenu class='uk-margin-remove-bottom'></TopMenu>
        <CounterSection />
        <VideoSection />
        <InfoSection />
        <!--
        todo
            Possible we need footer
         -->
    </div>
</template>

<script>
import TopMenu from './organisms/TopMenu.vue';
import CounterSection from './organisms/CounterSection';
import VideoSection from './organisms/VideoSection';
import InfoSection from './organisms/InfoSection';

/*
todo use those sections:
Atoms
Molecules
Organisms
Templates
Pages
 */

export default {
    name: 'App',
    components: {
        TopMenu, CounterSection, VideoSection, InfoSection

    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
