<template>
    <div>
        <div class='uk-card uk-card-default uk-card-hover uk-card-body uk-border-pill'>
            <h3 class='uk-text-lead uk-card-title'>Some info about event</h3>

            <div class='uk-grid-small' uk-grid>
                <div class='uk-width-expand' uk-leader='fill: -'>Where</div>
                <div>London</div>
            </div>

            <div class='uk-grid-small' uk-grid>
                <div class='uk-width-expand' uk-leader='fill: -'>When</div>
                <div>13/08/2022</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoTable'
};
</script>
