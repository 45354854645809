<template>
    <div style='position: relative; padding-top: 56.25%;'>
        <iframe allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
                allowfullscreen='true'
                src='https://iframe.videodelivery.net/9bb74ad6b07e294bfd4b7c842cfa26fc'
                style='border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;'
                uk-height-viewport></iframe>
    </div>
</template>

<script>
export default {
    name: 'VideoPlayer'
};
</script>
