<template>
    <div
        class="uk-grid-small  uk-child-width-auto uk-text-large uk-text-uppercase "
        uk-countdown="date: 2022-08-13T22:37:29+00:00"
        uk-grid
    >
        <div>
            <div class="uk-countdown-number uk-countdown-days"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Days</div>
        </div>
        <div class="uk-countdown-separator">:</div>
        <div>
            <div class="uk-countdown-number uk-countdown-hours"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Hours</div>
        </div>
        <div class="uk-countdown-separator">:</div>
        <div>
            <div class="uk-countdown-number uk-countdown-minutes"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Minutes</div>
        </div>
        <div class="uk-countdown-separator">:</div>
        <div>
            <div class="uk-countdown-number uk-countdown-seconds"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Seconds</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CounterClock'
};
</script>
