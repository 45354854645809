<template>
    <section
        class='uk-flex uk-flex-center uk-flex-middle uk-background-cover'
        uk-height-viewport>
        <InfoTable />
    </section>
</template>

<script>

import InfoTable from '../molecules/InfoTable.vue';

export default {
    name: 'InfoSection',
    components: {
        InfoTable
    }
};
</script>

<style scoped>
section {
    background-image: url('https://images.unsplash.com/photo-1429962714451-bb934ecdc4ec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
}
</style>
