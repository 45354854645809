<template>
    <section
        class='uk-flex uk-flex-center uk-flex-middle uk-flex-column  uk-background-cover uk-margin-remove-top '
        uk-height-viewport
    >
        <div>
            <div class='uk-card uk-card-default uk-card-hover uk-card-body uk-border-pill'>
                <h3 class='uk-text-lead uk-card-title'>Time till event begin</h3>
                <CounterClock></CounterClock>
            </div>
        </div>
    </section>
</template>

<script>

import CounterClock from '../molecules/CounterClock.vue';

export default {
    name: 'CounterSection',
    components: {
        CounterClock
    }
};
</script>
<style scoped>
section {
    background-image: url('https://images.unsplash.com/photo-1557787163-1635e2efb160?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80');
}
</style>
