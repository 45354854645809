<template>
    <section uk-height-viewport>
        <VideoPlayer></VideoPlayer>
    </section>
</template>

<script>

import VideoPlayer from '../molecules/VideoPlayer.vue';

export default {
    name: 'VideoSection',
    components: {
        VideoPlayer
    }
};
</script>
